import service from '../../utils/request.js'

export function aa(id = '') {
  return service.get('index/info/' + id, {
    item: 'item'
  })
}
export function getMenu() {
  return service.get('index/index/menu')
}

export function subpage(type) {
  return service({
    url: 'website/parent/list',
    method: 'get',
    params: { type: type }
  })
}

export function listByParentId(type) {
  return service({
    url: 'website/subitem/listByParentId',
    method: 'get',
    params: { type: type }
  })
}

export function details(id = '') {
  return service({
    url: 'website/subitem/info/' + id,
    method: 'get',
    item: 'item'
  })
}

// 提交
export function getSubmit(data = {}) {
  return service({
    url: `/website/message/save`,
    method: 'post',
    data: data
  })
}
// banner
export function getBanner() {
  return service({
    url: `/website/banner/getBanner`,
    method: 'get'
  })
}
// 根據id查詢招聘詳情
export function getDetails(id = '') {
  return service({
    url: '/website/staff/info/' + id,
    method: 'get',
    item: 'item'
  })
}
