<template>
  <div>
    <div>
      <img src="https://water-1302249616.cos.ap-nanjing.myqcloud.com//2021-12-07/68d95bed883f404da238ca33bf8b615a-banner_HZ物云.jpg" alt style="width:100%;">
    </div>
    <div class="tit-i">
      <img src="../assets/Group.png" alt>
      <h3>和仲动态</h3>
      <img src="../assets/Group Copy.png" alt>
      <h5>
        <span>SERVICE</span>
      </h5>
    </div>
    <div
      style="
      margin: 0px auto;
        width: 1216px;
    "
    >
      <template v-for="subitem in data">
        <div :key="subitem.id" class="dynamic">
          <div class="imgleft">
            <img :src="subitem.imgUrl" alt>
          </div>
          <div class="details">
            <div class="dynfont">
              <span class="min">{{ subitem.title }}</span>
              <br>
              <span class="minabout">{{ subitem.subTitle }}</span>
            </div>
            <div class="dynfonts ql-editor" v-html="subitem.detail">
              <!-- cew -->
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { subpage } from '../api/pc/home'
export default {
  data() {
    return {
      data: []
    }
  },
  created() {
    subpage(4)
      .then(res => {
        console.log('res', res)
        // res.data.item.parentDOS[1].subitemEntities.push(res.data.item.parentDOS[1].subitemEntities[0])
        this.data = res.data.items[0].subitemEntities
      })
      .catch(e => {
        console.error(e)
      })
    // for (let i = 0; i < this.list.length; i++) {
    //   const item = this.list[i];
    //   console.log(item);
    // }
  }
}
</script>

<style  lang="scss" scoped>
.tit-i {
  padding: 20px 0;
  text-align: center;
  padding: 50px 0;
  line-height: 36px;
  overflow: hidden;
  h3 {
    font-size: 30px;
    color: #002;
    margin-bottom: 0px;
    display: inline-block;
  }
  h5 {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: Arial;
    margin-top: 10px;
    span {
      color: #e4392a;
    }
  }
  em {
    width: 45px;
    border-bottom: 1px solid #7f7f90;
    display: inline-block;
  }
}
.dynamic {
  position: relative;
  .dynfont {
    margin-left: 24px;
    margin-top: 78px;
    .min {
      width: 133px;
      height: 37px;
      font-size: 26px;
      font-family: Helvetica;
      color: #4a4a4a;
      line-height: 31px;
    }
    .minabout {
      width: 133px;
      height: 17px;
      font-size: 14px;
      font-family: Helvetica;
      color: #bbbbbb;
      line-height: 17px;
    }
  }
  .dynfonts {
    width: 671px;
    height: 320px;
    font-size: 14px;
    font-family: Helvetica;
    color: #4a4a4a;
    line-height: 26px;
    margin-top: 30px;
    margin-left: 24px;
     overflow-y: auto;
     ::v-deep {
      img{
      max-width: 100%;
    }
    }
  }
  .imgleft {
    display: inline-block;
    margin-bottom: 100px;
    img {
      width: 542px;
      height: 596px;
    }
  }
  .details {
    position: absolute;
    display: inline-block;
    width: 727px;
    height: 489px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 26%);
    top: 58px;
    margin-left: -100px;
    border-radius: 10px;
  }
}
</style>
